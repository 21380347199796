var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "view-producer" }, [
    _vm._m(0),
    _c(
      "section",
      { staticClass: "section" },
      [
        _vm.isLoading
          ? _c(
              "div",
              { staticClass: "container container--fullWidth" },
              [_c("VLoadSpinner")],
              1
            )
          : [
              _c(
                "div",
                {
                  staticClass:
                    "container container--fullWidth grid grid__col-small-big",
                },
                [
                  _vm._m(1),
                  _c(
                    "div",
                    { staticClass: "wrapper--filled wrapper--paddingLg" },
                    [
                      _vm.activePromos.length === 0
                        ? _c("div", { staticClass: "h-textCenter" }, [
                            _c("h4", [
                              _vm._v(
                                "Currently you don't have any active promotions"
                              ),
                            ]),
                            _c("p", [_vm._v("Start a campaign below")]),
                          ])
                        : _vm._l(_vm.activePromos, function (promo, index) {
                            return _c(
                              "div",
                              { key: index, staticClass: "activePromo" },
                              [
                                _c("div", { staticClass: "flexbox" }, [
                                  _c("img", {
                                    staticClass: "activePromo__beatImage",
                                    attrs: {
                                      src: promo.beat.image_url
                                        ? promo.beat.image_url
                                        : _vm.authenticatedUser.image_logo_url,
                                    },
                                  }),
                                  _c(
                                    "p",
                                    { staticClass: "activePromo__beatName" },
                                    [_vm._v(_vm._s(promo.beat.name))]
                                  ),
                                ]),
                                _c("div", { staticClass: "flexbox" }, [
                                  _c(
                                    "p",
                                    { staticClass: "activePromo__section" },
                                    [_vm._v(_vm._s(promo.placement))]
                                  ),
                                  _c(
                                    "p",
                                    { staticClass: "activePromo__date" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("moment")(
                                            promo.day,
                                            "ddd, MMMM Do"
                                          )
                                        )
                                      ),
                                    ]
                                  ),
                                ]),
                              ]
                            )
                          }),
                    ],
                    2
                  ),
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "container container--fullWidth grid grid__col-small-big",
                },
                [
                  _vm._m(2),
                  _c(
                    "div",
                    { staticClass: "wrapper--filled wrapper--paddingLg" },
                    [
                      _c("h2", { staticClass: "h-mb-10" }, [
                        _vm._v("Placement"),
                      ]),
                      _c("div", { staticClass: "formGroup" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.promotionPlacement,
                              expression: "promotionPlacement",
                            },
                          ],
                          staticClass: "formRadio",
                          attrs: {
                            type: "radio",
                            id: "trending",
                            value: "Trending",
                          },
                          domProps: {
                            checked: _vm._q(_vm.promotionPlacement, "Trending"),
                          },
                          on: {
                            change: function ($event) {
                              _vm.promotionPlacement = "Trending"
                            },
                          },
                        }),
                        _vm._m(3),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.promotionPlacement,
                              expression: "promotionPlacement",
                            },
                          ],
                          staticClass: "formRadio",
                          attrs: {
                            type: "radio",
                            id: "homepage",
                            value: "Homepage",
                          },
                          domProps: {
                            checked: _vm._q(_vm.promotionPlacement, "Homepage"),
                          },
                          on: {
                            change: function ($event) {
                              _vm.promotionPlacement = "Homepage"
                            },
                          },
                        }),
                        _vm._m(4),
                        _c("div", { staticClass: "h-mb-10" }),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.promotionPlacement === "Trending",
                                expression: "promotionPlacement === 'Trending'",
                              },
                            ],
                          },
                          [
                            _c("p", [
                              _vm._v(
                                'Promoted beats will be shown in the "Trending" page, above the trending beats, as well as in the "Trending Charts" section inside the main page of the website.'
                              ),
                            ]),
                            _c("img", {
                              staticClass: "promotionPlacementImage",
                              attrs: { src: "img/ad-trending.png" },
                            }),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.promotionPlacement === "Homepage",
                                expression: "promotionPlacement === 'Homepage'",
                              },
                            ],
                          },
                          [
                            _c("p", [
                              _vm._v(
                                'In the homepage, a specific section called "Promoted Beats", right below the "Artist Type" section, will show all the beats being promoted.'
                              ),
                            ]),
                            _c("img", {
                              staticClass: "promotionPlacementImage",
                              attrs: { src: "img/ad-homepage.png" },
                            }),
                          ]
                        ),
                      ]),
                      _c("hr", { staticClass: "form__divider h-margin-30" }),
                      _c("h2", { staticClass: "h-mb-10" }, [_vm._v("Beat")]),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "click-outside",
                              rawName: "v-click-outside",
                              value: _vm.closeMenuDropdown,
                              expression: "closeMenuDropdown",
                            },
                          ],
                          staticClass: "formGroup dropdown",
                        },
                        [
                          _c(
                            "a",
                            {
                              staticClass:
                                "inputFlex formInput formInput--fullWidth formInput--selectCustom",
                              class: { hasError: _vm.paymentErrorMissingBeat },
                              on: { click: _vm.toggleMenuDropdown },
                            },
                            [
                              _vm.promotionBeat
                                ? _c("img", {
                                    staticClass: "beatImage beatImage--sm",
                                    attrs: { src: _vm.promotionBeat.image_url },
                                  })
                                : _vm._e(),
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.promotionBeat
                                      ? _vm.promotionBeat.name
                                      : "Select beat"
                                  )
                                ),
                              ]),
                              _c("icon", { attrs: { name: "arrow-down" } }),
                            ],
                            1
                          ),
                          _vm.menuDropDown
                            ? _c(
                                "ul",
                                {
                                  staticClass:
                                    "dropdown__menu dropdown__menu--withInput",
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.beatSearchInput,
                                        expression: "beatSearchInput",
                                      },
                                      {
                                        name: "focus",
                                        rawName: "v-focus",
                                        value: true,
                                        expression: "true",
                                      },
                                    ],
                                    staticClass:
                                      "formInput formInput--fullWidth",
                                    attrs: {
                                      type: "text",
                                      placeholder: "Filter Beats",
                                    },
                                    domProps: { value: _vm.beatSearchInput },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.beatSearchInput =
                                          $event.target.value
                                      },
                                    },
                                  }),
                                  _vm._l(_vm.filteredBeats, function (c) {
                                    return _c(
                                      "li",
                                      {
                                        key: c.id,
                                        staticClass:
                                          "dropdown__item dropdown__item--sm",
                                        class: {
                                          isActive:
                                            _vm.promotionBeat &&
                                            c === _vm.promotionBeat.name,
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.updatePromotedBeat(c)
                                          },
                                        },
                                      },
                                      [
                                        _c("img", {
                                          staticClass: "beatImage",
                                          attrs: { src: c.image_url },
                                        }),
                                        _c("p", [_vm._v(_vm._s(c.name))]),
                                      ]
                                    )
                                  }),
                                ],
                                2
                              )
                            : _vm._e(),
                        ]
                      ),
                      _c("hr", { staticClass: "form__divider h-margin-30" }),
                      _c("h2", { staticClass: "h-mb-10" }, [
                        _vm._v("Set Duration"),
                      ]),
                      _c("v-date-picker", {
                        attrs: {
                          mode: "multiple",
                          "title-position": "left",
                          columns: _vm.$screens({ lg: 2 }, 1),
                          color: "blue",
                          "is-dark": "",
                          "is-inline": "",
                          "is-expanded": "",
                          "available-dates": _vm.availableDates,
                        },
                        model: {
                          value: _vm.promotionDates,
                          callback: function ($$v) {
                            _vm.promotionDates = $$v
                          },
                          expression: "promotionDates",
                        },
                      }),
                      _c("div", { staticClass: "h-mt-10" }),
                      _vm._l(_vm.promotionDates, function (date, index) {
                        return _c(
                          "span",
                          { key: index, staticClass: "selectedDate" },
                          [_vm._v(_vm._s(_vm._f("moment")(date, "MMM Do")))]
                        )
                      }),
                      _c("hr", { staticClass: "form__divider h-margin-30" }),
                      _c("div", { staticClass: "formGroup" }, [
                        _c("div", {
                          staticClass: "h-mb-20",
                          attrs: { id: "dropin-container" },
                        }),
                        _c("p", [
                          _vm._v(
                            "Your order total is $" + _vm._s(_vm.orderTotal)
                          ),
                        ]),
                        _c(
                          "button",
                          {
                            staticClass: "btn btn--primary h-mt-10",
                            on: { click: _vm.makePayment },
                          },
                          [_vm._v("Create Campaign")]
                        ),
                      ]),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.paymentErrorShort,
                              expression: "paymentErrorShort",
                            },
                          ],
                          staticClass: "formGroup form__error",
                        },
                        [_vm._v(_vm._s(_vm.paymentErrorShort))]
                      ),
                    ],
                    2
                  ),
                ]
              ),
            ],
      ],
      2
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("header", { staticClass: "header header--borderBottom" }, [
      _c("div", { staticClass: "container headerContainer" }, [
        _c("div", { staticClass: "headerContainer__heading" }, [
          _c("h1", [_vm._v("Promotion")]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "h-margin-30" }, [
      _c("h2", [_vm._v("Active Promotions")]),
      _c("p", [_vm._v("Check your currently active campaigns")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "h-margin-30" }, [
      _c("h2", [_vm._v("New Promotion")]),
      _c("p", [_vm._v("Set up a new campaign")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { attrs: { for: "trending" } }, [
      _vm._v("Trending "),
      _c("span", [_vm._v("$20")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { attrs: { for: "homepage" } }, [
      _vm._v("Homepage "),
      _c("span", [_vm._v("$10")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }